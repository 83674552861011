const fonts = ["Edu VIC WA NT Beginner",
    "Ballet", "Caveat", 'Oswald',
    'Edu QLD Beginner', 'Dancing Script',
    'Yanone Kaffeesatz',
    "Cormorant SC",
    "Mochiy Pop One",
    "Anton",
    "Lobster",
    "Pacifico",
    "Secular One",
    "Shadows Into Light",
    "Square Peg",
    "Acme",
    "Baloo 2",
    "Courgette",
    "Lobster Two",
    "Great Vibes",
    "Kaushan Script",
    "Cookie",
    "Sacramento",
    "Rubik Moonrocks",
    "Press Start 2P",
    "Carter One",
    "Berkshire Swash",
    "Poiret One",
    "Titan One",
    "Bangers",
    "Tangerine",
    "Playball",
    "Parisienne",
    "Allura",
  "Homemade Apple",
    "Sriracha",
    "Unica One",
    "Chewy",
    "Macondo",
    "VT323",
    "VT323",
    "Leckerli One",
    "Cabin Sketch",
    "Rye",
    "Henny Penny",
    "Boogaloo",
    "Arizonia",
    "Short Stack",
    "Yesteryear",
    "Inspiration",
    "Send Flowers",
    "ZCOOL KuaiLe",
    "Sonsie One",
    "Ribeye",
    "Nova Flat",
    "Macondo Swash Caps",
    "Libre Barcode 39 Text",
    "Trade Winds",
    "Sarina",
    "Bigshot One",
    "Kranky",
    "Spicy Rice",
    "Unkempt",
    "Redressed",
    "Gorditas",
    "Julee",
    "Miniver",
    "Bellota",
    "Mystery Quest",
    "Ruthie",
    "Lovers Quarrel",
    "Train One",
    "Monofett",
    "Fontdiner Swanky",
    "Engagement",
    "Stick",
    "Stalemate",
    "Hachi Maru Pop",
    "Underdog",
    "Libre Barcode 39 Extended Text",
    "Overlock SC",
    "Zilla Slab Highlight",
    "Akronim",
    "Comforter",
    "Molle",
    "Mochiy Pop P One",
    "Akaya Telivigala",
    "Neonderthaw",
    "Metal Mania",
    "Barrio",
    "Nosifer",
    "Srisakdi",
    "Fascinate Inline",
    "Griffy",
    "Joti One",
    "Jim Nightshade",
    "Mrs Sheppards",
    "WindSong",
    "Caesar Dressing",
    "Libre Barcode 128 Text",
   



];


export default fonts;