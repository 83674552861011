

/** 
 CSS imports  */
import './App.css';
import Home from './Pages/Home';
import './styles/animista.css';
import './styles/w3.css';




function App() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
